/* =================== MINHA CONTA =================== */
jQuery(document).ready(function ($) {

  // Exibe/Oculta menu lateral (Mobile)
  $('.conta-lateral-menu-btn').on('click', function () {
    $('.conta-lateral-menu').slideToggle();
  });

  // Exibe a confirmação ao clicar no botão de remover 
  $('.btn-remover').on('click', function(e) {
    e.preventDefault();

    var idRemove = $(this).data('id-remove');
    $('#id_remove').val(idRemove);
    openModal('modal-confirm');
  }); 

});
